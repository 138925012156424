import { RouterProvider, createBrowserRouter, useNavigate } from "react-router-dom";
import "./App.css";
import FinalUI from "./components/FinalUI";
import TestApplication from "./components/TestApplication";
import { useEffect } from "react";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
    },
    {
      path: "/smd-validation",
      element: (
        <TestApplication>
          <FinalUI />
        </TestApplication>
      ),
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;

const Root = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/smd-validation");
  }, []);

  return <div>loading...</div>;
};

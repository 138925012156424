import { InformationCircleIcon } from "@heroicons/react/24/outline";
import React from "react";

const Base64Image = ({ base64String }) => {
  return (
    <div className="w-full">
      {base64String ? (
        <img
          src={`data:image/png;base64,${base64String}`}
          alt="Base64 Preview"
        />
      ) : (
        <div className="w-full bg-white rounded-lg p-3 flex-1 flex text-sm border text-red-500 items-center">
          <InformationCircleIcon className="h-5 w-5 mr-2" />
          Base64 string is empty
        </div>
      )}
    </div>
  );
};

export default Base64Image;

import React from "react";

const BaseAudioView = ({ base64Audio }) => {
  const audioRef = React.useRef(null);

  React.useEffect(() => {
    if (audioRef.current) {
      audioRef.current.src = `data:audio/mp3;base64,${base64Audio}`;
    }
  }, [base64Audio]);

  return base64Audio ? (
    <audio className="w-full" ref={audioRef} controls />
  ) : (
    <p>Base64 audio is empty</p>
  );
};

export default BaseAudioView;

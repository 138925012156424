import React from 'react'
import PropTypes from 'prop-types'

function TestApplication({ children }) {
  return (
    <div className=' w-full flex flex-1 flex-col gap-4 bg-slate-100 min-h-screen'>{children}</div>
  )
}

TestApplication.propTypes = {
  children: PropTypes.node.isRequired
}

export default TestApplication